<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>OTP Verification</h3>
          <form @submit.prevent="verifyOTP">
            <div class="mb-3">
              <label for="otp" class="form-lab el">OTP</label>
              <input
                type="otp"
                class="form-control"
                id="otp"
                v-model="otp"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.otp.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="text-end btn-row">
              <button
                type="submit"
                :disabled="isVerifying == true"
                class="btn cta-primary"
              >
                <span v-if="isVerifying == false">
                  Verify
                </span>
                <span v-else>
                  <circular-loader></circular-loader>
                </span>
              </button>
              <button
                @click="resendOTP"
                :disabled="isResending == true"
                class="btn cta-primary"
              >
                <span v-if="isResending == false">
                  Resend OTP
                </span>
                <span v-else>
                  <circular-loader></circular-loader>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "Login",
  components: {
    CircularLoader
  },
  data() {
    return {
      otp: "",
      isVerifying: false,
      isResending: false,
      errors: {}
    };
  },
  validations() {
    return {
      otp: {
        required: helpers.withMessage("Please enter otp", required)
      }
    };
  },
  methods: {
    verifyOTP: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isVerifying = true;
      AuthService.verifyEmail({
        otp: this.otp
      })
        .then(result => {
          if (result.data.status == "success") {
            this.$toast.success(result.data.message);
            this.$store.dispatch(
              "studentAuth/updateUser",
              JSON.stringify(result.data.user)
            );
            this.$router.push({
              name: "dashboardTab"
            });
            this.isVerifying = false;
          }
          if (result.data.status == "error") {
            this.$toast.error(result.data.message);
            this.$router.push({
              name: "emailVerification"
            });
            this.isVerifying = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.$router.push({
            name: "login"
          });
          this.isVerifying = false;
        });
    },
    resendOTP: function() {
      this.isResending = true;
      AuthService.resendOTP({
        otp_to: "email"
      })
        .then(result => {
          if (result.data.status == "success") {
            this.$toast.success(result.data.message);
            this.isResending = false;
          }
          if (result.data.status == "error") {
            this.$toast.error(result.data.message);
            this.isResending = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.isResending = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
